<mobile-page-part>

	<div class="info-content">Foo Bar Baz</div>

	<div class="d-flex gap-4 flex-column">

		<div>
			<h4 class="px-2">Pending</h4>
			@if(!messages().pending.length){
			<i class="p-2">
				You have no active messages. Good job!
			</i>
			}
			<div class="mobile-list-container">

				@for(message of messages().pending; track message.messageId;)
				{
				<a (click)="openMessageFlyout(message)" class="d-flex justify-content-left align-items-center gap-3">
					<i class='fa-regular fa-square fs-5'></i>
					<div class="d-flex flex-column">
						<small>
							{{message.category}}
							@if(message.stopUTC < nowUTC) { <span class="color-darkred">- Past Due</span>
								}
						</small>
						<span>{{message.title}}</span>
					</div>
				</a>
				}
			</div>
		</div>

		@if(messages().completed.length)
		{
		<div>
			<h4 class="px-2">Completed</h4>
			<div class="mobile-list-container">
				@for(message of messages().completed; track message.messageId;)
				{
				<a (click)="openMessageFlyout(message)" class="d-flex justify-content-left align-items-center gap-3">
					<i class='fa-solid fa-check fs-5'></i>
					<div class="d-flex flex-column">
						<small>{{message.category}}</small>
						<span>{{message.title}}</span>
					</div>
				</a>
				}
			</div>
		</div>
		}


		@if(app.data().remainingMessagesCount > 0)
		{
		<div class="text-center">
			<button class="btn btn-standard" (click)="loadMore()">Show Past Messages</button>
		</div>
		}
	</div>

</mobile-page-part>