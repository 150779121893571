import { Routes } from '@angular/router';
import { MobileError404Page } from '@eforall/mobile';
import { StaffMobileFrame } from './frame/staff-mobile/staff-mobile.frame';
import { ActivityPage, AppsPage, CalendarPage, DepartmentsPage, HomePage, MembersPage, MessagesPage, OrgChartPage, PoliciesPage, ProceduresPage, ProfilePage, StaffPage } from './pages';


export const routes: Routes = [
	{
		path: '',
		component: StaffMobileFrame,
		children: [
			{ path: '', component: HomePage },
			{ path: 'activity', component: ActivityPage },
			{ path: 'apps', component: AppsPage },
			{ path: 'calendar', component: CalendarPage },
			{ path: 'messages', component: MessagesPage },
			{
				path: 'staff',
				children: [
					{ path: '', component: StaffPage },
					{ path: 'departments', component: DepartmentsPage },
					{
						path: 'org-chart', children: [
							{ path: '', redirectTo: '/staff/org-chart/7', pathMatch: 'full' },
							{ path: ':staffId', component: OrgChartPage }
						]
					},
					{ path: 'members', component: MembersPage },
				]
			},
			{ path: 'profile', component: ProfilePage },
			{ path: 'policies', component: PoliciesPage },
			{ path: 'procedures', component: ProceduresPage },
			{ path: '**', component: MobileError404Page }
		]
	},


];
