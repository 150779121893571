@if(message(); as message)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<staff-department-header [department]="message.department"></staff-department-header>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="d-flex flex-column align-items-center gap-2">
			<i class="fa-duotone fa-message-captions" style="font-size: 5em;"></i>
			<h4>{{message.category}}</h4>
			<p class="message-title">{{message.title}}</p>

			<markdown [data]='message.markdown'></markdown>
		</div>
	</mobile-flyout-body-part>


	<mobile-flyout-footer-part>
		<div class="d-flex align-items-center flex-column justify-content-center gap-2">
			@if(!message.actionTakenUTC)
			{
			<button class="btn btn-primary" (click)="setActionTaken()">{{message.actionText}}</button>

			@if(message.deferText)
			{
			<button class="btn btn-standard" (click)="close()">{{message.deferText}}</button>
			}
			}
			@else {
			<i class="mt-1">You responded on {{util.date.formatUTC(message.actionTakenUTC, 'MMM D, YYYY',
				'No Time',
				'en-US')}}.</i>
			}
		</div>
	</mobile-flyout-footer-part>
</mobile-flyout-frame>
}