import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService, CommonIconTextWidget } from '@eforall/common';
import { MobileAvatarPart, MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart, MobileFrameService, MobileMenuItem } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { StaffDepartmentHeaderWidget } from '../../../widgets';


@Component({
	selector: 'staff-user-menu-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		StaffDepartmentHeaderWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
		MobileAvatarPart,
		RouterModule,
	],
	templateUrl: './user-menu-flyout.part.html',
	styleUrl: './user-menu-flyout.part.scss'
})
export class StaffUserMenuFlyoutPart {
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public frame = inject(MobileFrameService);

	private auth = inject(AuthService);

	public menu: MobileMenuItem[] = [
		{ label: 'Profile', routerLink: '/profile', iconStyle: 'fa-duotone', icon: 'fa-user', },
		{ label: 'Apps', routerLink: '/apps', iconStyle: 'fa-duotone', icon: 'fa-grid-2', },
		{ label: 'Departments', routerLink: '/staff/departments', iconStyle: 'fa-duotone', icon: 'fa-users-line', },
		{ label: 'Policies', routerLink: '/policies', iconStyle: 'fa-duotone', icon: 'fa-file', },
		{ label: 'Messages', routerLink: '/messages', iconStyle: 'fa-duotone', icon: 'fa-message-captions', },
		{ label: 'FAQs', routerLink: '/calendar', iconStyle: 'fa-duotone', icon: 'fa-calendars', },
		{ label: 'Admin App', url: 'https://eforall.app', iconStyle: 'fa-duotone', icon: 'fa-shield-halved', },
		{ label: 'Tech Support', url: 'https://chat.google.com/room/AAAAlOvAAAc', iconStyle: 'fa-duotone', icon: 'fa-circle-question', },
	];

	signOut() {
		this.auth.signOut();
		this.activeOffcanvas.dismiss();
	}

	close() {
		this.activeOffcanvas.close();
	}
}