import { Component, inject } from '@angular/core';
import { FlyoutService } from '../flyout.service';
import { StaffMessageFlyoutPart } from '../message/message-flyout.part';
import { StaffSoftwareFlyoutPart } from '../software/software-flyout.part';
import { StaffFlyoutPart } from '../staff/staff-flyout.part';
import { StaffDepartmentFlyoutPart } from '../department/department-flyout.part';
import { StaffUserMenuFlyoutPart } from '../user-menu/user-menu-flyout.part';


@Component({
	selector: 'flyout-container-part',
	standalone: true,
	imports: [StaffMessageFlyoutPart, StaffSoftwareFlyoutPart, StaffFlyoutPart, StaffDepartmentFlyoutPart, StaffUserMenuFlyoutPart],
	templateUrl: './flyout-container.part.html',
	styles: [`
		:host {
			height:100%;
		}
	`],
})
export class StaffFlyoutContainerPart {

	public flyoutService = inject(FlyoutService);

}