import { Component, inject, input } from '@angular/core';
import { Message } from '@app-interfaces';
import { CommonIconTextWidget, UtilityService } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { AppService, FlyoutService, FuncService } from '../..';
import { StaffDepartmentHeaderWidget } from 'widgets';


@Component({
	selector: 'staff-message-flyout-part',
	standalone: true,
	imports: [
		CommonIconTextWidget,
		StaffDepartmentHeaderWidget,
		MarkdownModule,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
	],
	providers: [provideMarkdown()],
	templateUrl: './message-flyout.part.html',
	styleUrl: './message-flyout.part.scss'
})
export class StaffMessageFlyoutPart {
	public flyoutService = inject(FlyoutService);
	private func = inject(FuncService);
	private app = inject(AppService);
	public util = inject(UtilityService);
	private activeOffcanvas = inject(NgbActiveOffcanvas);


	public readonly message = input.required<Message>();

	async setActionTaken() {
		const data = this.message();
		if (data) {
			const { messages, remainingMessagesCount } = await this.func.message.setActionTakenUtc({ messageId: data.messageId });
			this.app.setMessages(messages, remainingMessagesCount);
			this.close();
		}
	}

	close() {
		this.activeOffcanvas.close();

	}

}