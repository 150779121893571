import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService, EnvironmentService, SpinnerService, UrlService, UtilityService } from '@eforall/common';
import { MobileFrameService, MobileLayoutService, MobileSpinnerWidget } from '@eforall/mobile';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../environments';
import { AppService, FlyoutService } from '../../services';
import { getMobileFrameConfig } from './get-mobile-frame-config';


@Component({
	selector: 'bootstrap-page',
	standalone: true,
	imports: [
		MobileSpinnerWidget,
		NgbModule,
		RouterOutlet,
	],
	templateUrl: `bootstrap.page.html`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BootstrapPage {

	public spinnerService = inject(SpinnerService);
	private env = inject(EnvironmentService);
	private auth = inject(AuthService);
	private router = inject(Router);
	private util = inject(UtilityService);
	private urlService = inject(UrlService);
	private frame = inject(MobileFrameService);
	private layout = inject(MobileLayoutService);
	private ngbTooltipConfig = inject(NgbTooltipConfig);
	// private pwa = inject(PwaService);

	public app = inject(AppService);
	private flyoutService = inject(FlyoutService);


	constructor() {

		this.auth.setData(
			this.app.loadData.bind(this.app),
			this.app.clearData.bind(this.app),
		);

		this.env.setEnvironment(environment);

		this.init();
		this.setNgbTooltipConfig();
	}

	init() {
		this.frame.initialize(getMobileFrameConfig(this.app, this.flyoutService));
	}

	setNgbTooltipConfig() {
		this.ngbTooltipConfig.triggers = 'hover';
		this.ngbTooltipConfig.openDelay = 2000;
		this.ngbTooltipConfig.closeDelay = 100;
	}
}
