import { computed } from '@angular/core';
import { MobileFrameConfig } from '@eforall/mobile';
import { AppService, FlyoutService } from '../../services';


export function getMobileFrameConfig(app: AppService, flyoutService: FlyoutService) {
	return computed(() => {
		const data = app.data();

		const items: MobileFrameConfig = {
			user: {
				staffId: data.user.staffId,
				firstName: data.user.firstName,
				lastName: data.user.lastName,
				email: data.user.email,
				avatar: data.user.avatar,
				mobilePhone: data.user.mobilePhone,
				department: data.user.department ? {
					departmentId: data.user.department.departmentId,
					icon: data.user.department.icon,
					name: data.user.department.name,
				} : {
					departmentId: 0,
					name: '',
					icon: ''
				}

			},
			navBarItems: [
				{
					label: 'Activity',
					routerLink: '/activity',
					iconStyle: 'fa-duotone',
					icon: 'fa-star-half color-mediumpurple',
					iconColor: 'color-mediumpurple',
				},
				{
					label: 'How To',
					routerLink: '/procedures',
					iconStyle: 'fa-duotone',
					icon: 'fa-list-check color-gold',
					iconColor: 'color-gold',
				},
				{
					label: 'Home',
					routerLink: '/',
					iconStyle: 'fa-duotone',
					icon: 'fa-home',
					iconColor: 'color-eforall-blue',
				},
				{
					label: 'Staff',
					routerLink: '/staff',
					iconStyle: 'fa-duotone',
					icon: 'fa-people-group color-violet',
					iconColor: 'color-violet',
				},
				{
					label: 'Calendar',
					routerLink: '/calendar',
					iconStyle: 'fa-duotone',
					icon: 'fa-calendars color-green',
					iconColor: 'color-green',
				},

			],

			openUserMenu: () => flyoutService.showUserMenu(),
		}

		return items;
	});
}