<div class="d-flex gap-4 flex-column">

	<div class="mobile-list-container">

		@for(dept of departments(); track dept.departmentId;)
		{

		<a (click)="openDepartmentFlyout(dept)" class="d-flex justify-content-left align-items-center gap-3">
			<i class="fa fa-circle"></i>
			<div>
				<div>{{dept.name}}</div>
				<small>{{ dept.head.fullName }}</small>
			</div>
		</a>

		}
	</div>
</div>