import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MobileListLinkPart } from './link-part/mobile-list-link.part';


interface MobileListItem {
	icon: string,
	iconStyle: string,
	text: string,
	callback?: () => void,
	subText?: string,
}

export interface MobileListWithSubItem extends MobileListItem {
	/**
	 * Supports just one level subItems
	 */
	subItems?: MobileListItem[],
}

@Component({
	selector: 'mobile-list',
	imports: [CommonModule, NgbAccordionModule, MobileListLinkPart],
	standalone: true,
	templateUrl: './mobile-list.widget.html',
	styles: [`
		.mobile-list {
			background-color: white;
			border-radius: 7px;
			border: 1px solid #ddd;
			user-select: none;
			overflow: hidden;

			.accordion-item {
				border:none;
			}

			.accordion-body {
				padding: 0;

				::ng-deep button {
					padding-left: 40px !important;
				}
			}


			button {
				width: 100%;
				cursor: pointer;
				text-decoration: none;
				padding: 0.5em 0.75em;
				color: #1f1f1f;
				height: 54px;
				border-bottom: 1px solid #ddd;
				border-radius:0;

				&:hover {
					background-color: rgba(0, 0, 0, 0.03);
				}

			}
		}
	`],
})
export class MobileListWidget {

	public items = input.required<MobileListWithSubItem[]>();
	public height = input<number | undefined>(undefined);
}
