import { FuncData } from '@interfaces';

export const EMPTY_DATA: FuncData = {

	asOfUtc: 0,

	userStaffId: 0,
	staff: [],
	departments: [],
	teams: [],
	teamMembers: [],
	jobRole: [],
	softwares: [],
	messages: [],
	remainingMessagesCount: 0,
};