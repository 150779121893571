import { Component, computed, inject } from '@angular/core';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { AuthService, CommonIconTextWidget, EnvironmentService, UtilityService } from '@eforall/common';
import { MobilePagePart } from '@eforall/mobile';
import { AppService } from '../../services';

interface AppTile {
	logoURI: string,
	appName: string,
	url: string,
	cardClass?: string,
}

@Component({
	selector: 'home-page',
	standalone: true,
	imports: [
		MobilePagePart,
		CommonIconTextWidget,
		RouterModule,
		RouterOutlet,
	],
	templateUrl: './home.page.html',
	styleUrl: './home.page.scss',
})
export class HomePage {

	public env = inject(EnvironmentService);
	public auth = inject(AuthService);
	public app = inject(AppService);
	public router = inject(Router);
	public util = inject(UtilityService);

	public currentUTC = Date.now() / 1000;


	public readonly pendingMessages = computed<string | undefined>(() => {

		const currentUTC = Date.now() / 1000;
		const pending = this.app.data().messages.filter(message => !message.actionTakenUTC && message.startUTC < currentUTC && currentUTC < message.stopUTC);
		const count = pending.length

		if (count > 1) return count + ` Pending Messages`;
		else if (count == 1) return `1 Pending Message`;
		else return undefined;
	});


	public readonly appTiles = computed<AppTile[]>(() => {
		const pinnedSoftwares = this.app.data().softwares.filter(software => software.isProvisioned && !!software.pinnedOrder);

		return pinnedSoftwares.map(userSoftware => ({
			appName: userSoftware.shortName,
			logoURI: userSoftware.logo,
			url: userSoftware.url,
		}));
	});


	navigateTo(app: AppTile) {
		window.open(app.url, app.appName);
	}

}
