import { Component, inject, input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Software } from '@app-interfaces';
import { CommonIconTextWidget } from '@eforall/common';
import { MobileFlyoutBodyPart, MobileFlyoutFooterPart, MobileFlyoutFrame, MobileFlyoutHeaderPart } from '@eforall/mobile';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { provideMarkdown } from 'ngx-markdown';
import { AppService, FuncService } from '../../../services';
import { StaffDepartmentHeaderWidget } from 'widgets';



@Component({
	selector: 'staff-software-flyout-part',
	standalone: true,
	imports: [
		RouterModule,
		StaffDepartmentHeaderWidget,
		CommonIconTextWidget,
		MobileFlyoutBodyPart,
		MobileFlyoutFooterPart,
		MobileFlyoutHeaderPart,
		MobileFlyoutFrame,
	],
	providers: [provideMarkdown()],
	templateUrl: './software-flyout.part.html',
	styleUrl: './software-flyout.part.scss'
})
export class StaffSoftwareFlyoutPart {
	private func = inject(FuncService);
	private app = inject(AppService);
	private activeOffcanvas = inject(NgbActiveOffcanvas);
	public router = inject(Router);


	public readonly software = input.required<Software>();

	navigateTo() {
		if (this.software()?.url) {
			window.open(this.software()?.url, this.software()?.shortName);
			this.close();
		}

	}

	async setPinned() {
		const software = this.software();
		if (software) {
			const softwares = await this.func.software.setPinnedOrder({ softwareId: software.softwareId });
			this.app.setSoftware(softwares);
			this.close();
		}
	}


	close() {
		this.activeOffcanvas.close();
	}
}