import { Component, computed, inject } from '@angular/core';
import { Message } from '@app-interfaces';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AppService, FlyoutService, FuncService } from '../../services';


@Component({
	selector: 'messages-page',
	standalone: true,
	imports: [
		MobilePagePart,
	],
	templateUrl: './messages.page.html'
})

export class MessagesPage {
	private frame = inject(MobileFrameService);
	public app = inject(AppService);
	private func = inject(FuncService);
	private flyoutService = inject(FlyoutService);

	public nowUTC = Date.now() / 1000;


	public readonly messages = computed<{ pending: Message[], completed: Message[] }>(() => {
		return {
			pending: this.app.data().messages.filter(message => !message.actionTakenUTC),
			completed: this.app.data().messages.filter(message => message.actionTakenUTC),
		};
	});

	constructor() {
		this.frame.setUrlMetadata({
			url: '/messages',
			backUrl: '/',
			pageName: computed(() => `Messages`),
			headerText: computed(() => `Messages`),
		});
	}

	openMessageFlyout(message: Message) {
		this.flyoutService.showMessage(message);
	}


	async loadMore() {
		const { messages, remainingMessagesCount } = await this.func.getAllMessages();
		this.app.setMessages(messages, remainingMessagesCount);

	}
}