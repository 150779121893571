@if(software(); as software)
{

<mobile-flyout-frame (closed)="close()">

	<mobile-flyout-header-part>
		<staff-department-header [department]="software.department"></staff-department-header>
	</mobile-flyout-header-part>


	<mobile-flyout-body-part>
		<div class="d-flex flex-column align-items-center gap-2">
			<div class="app-logo mb-2" [style.background-image]="'url(' + software.logo + ')'"></div>
			<h4>{{software.longName}}</h4>
			<p class="align-self-start">{{software.description}}</p>

			@if(!software.isProvisioned){
			<div class="align-self-start">
				You are not configured to use this app. If you beleive this is incorrect, discuss this with
				{{software.department.head.firstName}} {{software.department.head.lastName}}.
			</div>
			}
		</div>

	</mobile-flyout-body-part>

	@if(software.isProvisioned)
	{
	<mobile-flyout-footer-part>
		<div class="d-flex align-items-center flex-column justify-content-center gap-2">
			<button class="btn btn-primary" (click)="setPinned()">
				<common-icon-text [iconStyle]="software.pinnedOrder? 'fa-solid': 'fa-regular'" icon="fa-thumbtack"
					[text]="software.pinnedOrder? 'Unpin': 'Pin'" spacing="large"></common-icon-text>
			</button>

			<button class="btn btn-primary" (click)="navigateTo()">
				<common-icon-text icon="fa-arrow-right" text="Open" spacing="large"></common-icon-text>
			</button>
		</div>
	</mobile-flyout-footer-part>
	}
</mobile-flyout-frame>
}