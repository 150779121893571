import { Component, inject, input } from '@angular/core';
import { Department } from '@app-interfaces';
import { FlyoutService } from '../../services';


@Component({
	selector: 'departments-list',
	standalone: true,
	imports: [
	],
	templateUrl: './departments-list.widget.html',
})

export class DepartmentsListWidget {

	private flyoutService = inject(FlyoutService);

	public readonly departments = input.required<Department[]>();

	openDepartmentFlyout(dept: Department) {
		this.flyoutService.showDepartment(dept);
	}
}