import { AppData, Department, Message, Software, Staff } from '@app-interfaces';
import { UtilityService } from '@eforall/common';
import { FuncData } from '@interfaces';

/**
 * Convert raw app data into structures that can more
 * easily used the app (e.g. maps)
  */
export function processData(data: FuncData, util: UtilityService): AppData {


	const staff: Staff[] = data.staff.map(staff => ({
		...staff,
		fullName: `${staff.firstName} ${staff.lastName}`,
		directReports: [],
		teams: [],
	}));

	const staffById = util.array.toMap(staff, staff => staff.staffId);

	//
	// Departments
	//
	const departments: Department[] = data.departments.map(dept => ({ ...dept, head: staffById[dept.headStaffId]! }));
	const departmentById = util.array.toMap(departments, dept => dept.departmentId);


	//
	// Job role
	//
	const jobRoleById = util.array.toMap(data.jobRole, role => role.jobRoleId);



	//
	// Tack manager and department on to staff
	//
	for (const member of staff) {
		member.manager = staffById[member.managerStaffId ?? 0];
		member.department = departmentById[member.departmentId ?? 0];
		member.jobRole = jobRoleById[member.jobRoleId ?? 0];
		member.directReports = staff.filter(s => s.managerStaffId == member.staffId);
		member.teams = [];
	}


	const softwares: Software[] = data.softwares.map(software => ({
		...software,
		department: departmentById[software.departmentId]!,
		owner: staffById[software.ownerStaffId]!,
	}));


	const messages: Message[] = data.messages.map(message => {
		return {
			...message,
			department: departmentById[message.departmentId]!,
			createdBy: staffById[message.createdByStaffId]!,
		};
	});

	return {
		asOfUtc: data.asOfUtc,
		staff,
		user: staffById[data.userStaffId]!,
		departments,
		softwares,
		messages,
		remainingMessagesCount: data.remainingMessagesCount,
	};
}