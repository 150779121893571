import { Component, computed, inject } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { MobileFrameService, MobilePagePart } from '@eforall/mobile';
import { AppService } from 'services';
import { DepartmentsListWidget } from '../../../widgets';


@Component({
	selector: 'departments-page',
	standalone: true,
	imports: [
		MobilePagePart,
		DepartmentsListWidget,
	],
	templateUrl: './departments.page.html'
})

export class DepartmentsPage {

	private frame = inject(MobileFrameService);
	private app = inject(AppService);
	public util = inject(UtilityService);


	public departments = computed(() => this.app.data().departments);

	public currentUTC = Date.now() / 1000;

	constructor() {
		this.frame.setUrlMetadata({
			url: '/staff/departments',
			backUrl: '/staff',
			pageName: computed(() => `Departments`),
			headerText: computed(() => `Departments`),
		});
	}

}