import { AngularEnvironment } from './angular-environment';

export const environment: AngularEnvironment = {
	name: 'DEV',
	appName: 'Staff',
	baseUrl: 'https://staff-dev.eforall.org/',
	bodyCssClasses: [],
	frame: 'Mobile',
	authType: 'Internal',
	supportsSpanish: false,
	functionUrl: "https://us-central1-eforall-staff-dev.cloudfunctions.net/",
	firebaseConfig: {
		apiKey: "AIzaSyCEbeuRdjcL3-d6RMwGvzIxzNByTKbPbqc",
		authDomain: "staff-dev.eforall.org",
		projectId: "eforall-staff-dev",
		storageBucket: "eforall-staff-dev.appspot.com",
		messagingSenderId: "346608116814",
		appId: "1:346608116814:web:1fa81ab97c5cf7fe9a0f1f",
		measurementId: "G-HJRGX6BGG4",
	},
};
