import { Injectable, computed, inject, signal } from '@angular/core';
import { UtilityService } from '@eforall/common';
import { FuncData, MessageData, SoftwareData } from '@interfaces';
import { FuncService } from '../func/func.service.gen';
import { funcDataEquality } from './func-data-equality';
import { EMPTY_DATA } from './empty-data';
import { processData } from './process-data';


@Injectable({ providedIn: 'root' })
export class AppService {

	private util = inject(UtilityService);
	private readonly func = inject(FuncService);

	private readonly _data = signal<FuncData>(EMPTY_DATA, { equal: funcDataEquality })
	public readonly data = computed(() => processData(this._data(), this.util));


	public async loadData(): Promise<void> {
		this._data.set(await this.func.getAllData());
	}


	public async clearData(): Promise<void> {
		this._data.set(EMPTY_DATA);
	}


	public setMessages(
		messages: MessageData[],
		remainingMessagesCount: number,
	) {

		const data: FuncData = {
			...this._data(),
			asOfUtc: Date.now() / 1000,
			messages: [...messages],
			remainingMessagesCount,
		};

		this._data.set(data);
	}


	public setSoftware(
		softwares: SoftwareData[],
	) {

		const data: FuncData = {
			...this._data(),
			asOfUtc: Date.now() / 1000,
			softwares: [...softwares],
		};

		this._data.set(data);
	}


	public async update<T>(action: (payload: T) => Promise<FuncData>, payload: T): Promise<void> {
		this._data.set(await action(payload));
	}
}