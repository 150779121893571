import { Component, computed, inject, model } from '@angular/core';
import { Staff } from '@app-interfaces';
import { CommonSelectorItem, CommonSelectorWidget } from '@eforall/common';
import { AppService } from '../../services';


@Component({
	selector: 'staff-selector',
	standalone: true,
	imports: [CommonSelectorWidget],
	template: `<common-selector [(selectedId)]='selectedStaffId' [items]="items()"></common-selector>`,
})


export class StaffSelectorWidget {

	private readonly app = inject(AppService);

	public readonly selectedStaffId = model<number | undefined>(undefined);

	public readonly items = computed(() => {
		return this.app.data().staff.map(staff => {
			const item: CommonSelectorItem<Staff> = {
				id: staff.staffId,
				label: staff.fullName,
				iconStyle: 'fa-regular',
				icon: 'fa-person',
				data: staff,
			};

			return item;
		});
	});


	public readonly selectedStaff = computed(() => {
		return this.app.data().staff.find(staff => this.selectedStaffId() == staff.staffId);
	});

}


