<div class="d-flex gap-4 flex-column">

	<div class="mobile-list-container">

		@for(staff of staff(); track staff.staffId;)
		{

		@if(showAncestorCounts()) {
		@if(staff.directs + staff.indirects; as ancestors) {
		<div class="ancestor-count">
			<div>+{{ ancestors }}</div>
		</div>
		}}

		<a (click)="openStaffFlyout(staff)" class="d-flex justify-content-left align-items-center gap-3">
			<mobile-avatar-part [avatar]="staff.avatar" size="35px"></mobile-avatar-part>
			<div>
				<div>{{staff.fullName}}</div>
				<small [class.missing-data]="!staff.jobRoleId">{{ staff.jobRole?.en || 'Missing Role' }}</small>
			</div>
		</a>

		}
	</div>
</div>